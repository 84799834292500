import { BaseTextFieldProps, BoxProps } from '@material-ui/core';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { Dictionary } from 'lodash';
import { FBEditorOptionsProps, FBEditorPropertiesType, FBEditorValidationMode, FBInlineApprovalRole, FBInputState, FBLabelProps, FBOptionProps, FBProviderProps, FBSchemaState } from '..';
import { Employee } from '../../../state/ducks/company/types';
import { GroupRequestBody } from '../../../state/ducks/groupManagement/types';

type FBInputTransform = 'upper' | 'lower';
export type FBVisibilityType = 'visible' | 'hidden';

export type FBInputType =
  | 'url'
  | 'number'
  | 'textfield'
  | 'checkbox'
  | 'checkboxgroup'
  | 'checkboxgroupasync'
  | 'section'
  | 'subsection'
  | 'datepicker'
  | 'texteditor'
  | 'select'
  | 'contacts'
  | 'certifications'
  | 'references'
  | 'productorservice'
  | 'radio'
  | 'radiogroup'
  | 'autocomplete'
  | 'autocompleteasync'
  | 'hyperlink'
  | 'fileupload'
  | 'zipattachment'
  | 'schemaItem'
  | 'action'
  | 'summarysection'
  | 'editoroptions'
  | 'schemaselect'
  | 'paragraph'
  | 'documentRevisions'
  | 'instructionsmediaupload'
  | 'evidencemediaupload'
  | 'purchaseorder'
  | 'approvalMatrix'
  | 'approvalRoles'
  | 'poreceive'
  | 'stepsection'
  | 'teststep'
  | 'checkpoint'
  | 'approval'
  | 'inlineapproval'
  | 'createlabel'
  | 'quantity'
  | 'timer'
  | 'requestapproval'
  | 'instructionsdocument'
  | 'selectdocument'
  | 'hellosign'
  | 'customcontrol'
  | 'poApprovalMatrix'
  | 'task'
  | 'approvers'
  | 'blanktplform'
  | 'approvals'
  | 'procedure'
  | 'parts'
  | 'procedurediff'
  | 'ebpart'
  | 'eqmaintenance'
  | 'textfieldwithrange'
  | 'range'
  | 'passpercentage'
  | 'supplier'
  | 'mpiprocedure'
  | 'lhrstep'
  | 'unitMeasures'
  | 'quantityunit'
  | 'partsselection'
  | 'mediaalbum'
  | 'poapprovals'
  | 'lhrbuild'
  | 'purchaseordersummary'
  | 'siteInformation'
  | 'headquarterAddress'
  | 'downloadsheet'
  | 'lotTransfers'
  | 'lotQuantity'
  | 'lotOnHandQuantity'
  | 'partVendors'
  | 'traineeList'
  | 'numeric'
  | 'cycleCount'
  | 'workOrderPartDetails'
  | 'tasksListTable'
  | 'workOrderLhrTable'
  | 'woApprovals'
  | 'workOrderType'
  | 'shippingInformation'
  | 'headquarterAddress'
  | 'shippingItems'
  | 'shippingAddress'
  | 'supplierContacts'
  | 'supplierOfferingList'
  | 'supplierSupportingDocs'
  | 'supplierReferences'
  ;

export type FBInputProps =
  & FBLabelProps
  & FBOptionProps
  & FBEditorOptionsProps
  & Pick<AutocompleteProps, 'multiple'>
  & Pick<FBProviderProps, 'formState'>
  & Pick<BaseTextFieldProps,
  | 'children'
  | 'error'
  | 'value'
  | 'disabled'
  | 'name'
  | 'helperText'
  | 'rows'
  | 'placeholder'
  | 'defaultChecked'
  | 'hidden'
  > & {
    onBlur?: (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => any
    onChange?: (ev: React.ChangeEvent<any> | any, value: any, context?: FBSchemaState) => any
    setTranform?: (value: string) => string
    type?: FBInputType
    index?: number
    stepIndex?: number
    gutter?: boolean
    linkUrl?: string
    defaultValue?: any
    inputState?: FBInputState
    editorProperties?: FBEditorPropertiesType[]
    includeContext?: boolean
    rules?: any
    validationMode?: FBEditorValidationMode
    includeSelectedSchema?: boolean
    paragraph?: string
    isTemplate?: boolean
    includeQRScan?: boolean
    isInputOwner?: boolean
    designDisabled?: boolean
    tabId?: string
    removeBorder?: boolean
    isFbEditorField?: boolean
    moveable?: boolean
    referenceDataType?: string
    fieldNames?: string[]
    /**
     * @cfg {Bool} Whether the field can be deleted in the output document.
     */
    deleteDisabled?: boolean
    /**
     * @cfg {Bool} Whether the field can be deleted in the form.
     */
    deleteInFormDisabled?: boolean
    instructionsDocument?: string
    reviseDisabled?: boolean
    outputDisabled?: boolean
    approvers?: string[] | Employee[]
    approverGroups?: string[] | GroupRequestBody[]
    approvalRoles?: string[] | FBInlineApprovalRole[]
    approveIsBlocking?: boolean
    applicableOn?: any[]
    actions?: string[]
    hellosignFlowProps?: any
    showErrorMsg?: boolean
    // Field is under validation for validation mode (e.g show/hide)
    // but should not be included in the next form validation flow (e.g on form save)
    omitFinalValidation?: boolean
    validators?: Dictionary<any>
    forceDefault?: boolean
    autosave?: boolean
    transform?: FBInputTransform
    dataCy?: string
    withState?: boolean
    bookmarked?: boolean
    /**
     * @cfg {Bool} Whether the type added to the schema must be unique.
     * El with a type marked as a uniqueSchemaItem can be added to the schema only once.
     */
    uniqueSchemaItem?: boolean
    /**
     * @cfg {Bool} Include component into validation schema select option.
     */
    applicableValidationSchema?: boolean
    /**
     * @cfg {Bool} Any additional config we want to pass to the input.
     */
    editorConfig?: any
    /**
     * @cfg {Bool} MUI Box props that can be passed to input section.
     */
    boxProps?: BoxProps
    inspectionSection?: boolean
    /**
     * @cfg {String} Validation attribute name. Use this prop for non labeled controls.
     */
    validationAttribute?: string
    /**
     * @cfg {Bool} Flag for control inputs which values we need to generate control's
     * value but we need to omit it from form values.
     */
    omitFormValue?: boolean
    /**
     * @cfg {Bool} Item is deleted. It will not be visible but will remain in scheme.
     */
    deleted?: boolean
    // /**
    //  * @cfg {Bool} true to force form validation on input render if its default value
    //  * is validation relation.
    //  */
    // validateDefaultValue?: boolean;
    includeNotes?: boolean
    isOutput?: boolean
    /**
     * @cfg {Bool} If true focus the texteditor on render.
     */
    autoFocus?: boolean
    /**
     * @cfg {Bool} If true scroll to el on render.
     */
    autoScrollTo?: boolean

    /**
     * @cfg {string} Username that holds the lock.
     */
    lockOwnerName?: string
    /**
     * @cfg {Bool} MUI Box props that can be passed to input section.
     */
    labelProps?: BoxProps
    isAddAction?: boolean
    isChangeControlConfigTooltipsEnabled?: boolean
  };

export const OmitInputClones: FBInputType[] = [
  'section',
  'documentRevisions',
  'approvers',
  'approval',
  'blanktplform',
  'approvals',
  'procedure',
  'mpiprocedure',
  'procedurediff',
  'eqmaintenance',
  'ebpart',
  'parts',
  'range',
  'passpercentage',
  'poreceive',
  'quantityunit',
  'instructionsmediaupload',
  'evidencemediaupload',
  'mediaalbum',
  'lhrstep',
  'lhrbuild',
  'downloadsheet',
];

export const OmitIncludeNotes: FBInputType[] = [
  'stepsection',
  'teststep',
  'section',
  'subsection',
];

export const FBAutosave: FBInputType[] = [
  'textfield',
  'checkboxgroup',
  'autocomplete',
  'select',
  'quantity',
  'texteditor',
  'lotTransfers',
  'partVendors',
  'traineeList',
  'numeric',
  'cycleCount',
  'workOrderType',
  'supplierContacts',
  'supplierOfferingList',
  'supplierSupportingDocs',
  'supplierReferences',
];

export const SectionTypeField: FBInputType[] = [
  'section',
  'subsection',
];

export const BlockTypeField: FBInputType[] = [
  'section',
  'approvals',
  'blanktplform',
  'purchaseorder',
  'poapprovals',
  'poApprovalMatrix',
  'eqmaintenance',
  'stepsection',
  'teststep',
];

export interface FBInputConstructor {
  value: any
  hidden?: boolean
}
