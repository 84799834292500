import { useFormikContext } from 'formik';
import React from 'react';
import {
  EMPTY_VALUE_PLACEHOLDER,
  Mode,
  MODE_FIELD,
} from '../../../components/KendoDataGrid/constants';
import OverflowContent from '../../../components/OverflowContent';
import { CustomTemplateProps, EditableProductORService } from '../types';

export const LifeCyclePhaseCell: React.FC<CustomTemplateProps> = ({
  field,
  dataItem,
  onClick,
}) => {
  const formik = useFormikContext<EditableProductORService>();
  const displayValue = [Mode.add, Mode.edit].includes(dataItem[MODE_FIELD])
    ? formik?.values[field] || dataItem[field]
    : dataItem[field];
  const handleClick = () => onClick?.({ dataItem });

  return (
    <OverflowContent onClick={handleClick}>
      {displayValue ?? EMPTY_VALUE_PLACEHOLDER}
    </OverflowContent>
  );
};
