import { chain, filter, find, omit, pick } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import { sortStringsByKey } from '../../../common/utils/helpers';
import Config from '../../../config';
import { ThemeEnv } from '../../../ui/layout/theme/utils/types';
import { ApplicationState } from '../../reducers';
import { AsyncState } from '../../types';
import { WHITE_LABEL_DEFAULT } from './constants';
import { PremiumFeature, PremiumFeatureSubscription } from './types';

const getSelectedCompany = (state: ApplicationState) => state.company.selectedCompany;
const companyIdSelector = (state: ApplicationState) => state.company.companyMine.id;
const companyNameSelector = (state: ApplicationState) => state.company.companyMine.name;
const slackTeamId = (state: ApplicationState) => state.company.selectedCompany.teamId;
const isSlackIntegrationEnabled = (state: ApplicationState) => state.company.selectedCompany.slackIntegrationEnabled;
const isHelloSignIntegrationEnabled = (state: ApplicationState) =>
  state.company.companyMine.helloSignIntegrationEnabled;

const getWhiteLabeling = createSelector(
  getSelectedCompany,
  (selectedCompany) => (selectedCompany.whiteLabeling || {})[Config.Theme as ThemeEnv] || WHITE_LABEL_DEFAULT,
);

const getAllWhiteLabeling = createSelector(
  getSelectedCompany,
  (selectedCompany) => selectedCompany.whiteLabeling || WHITE_LABEL_DEFAULT,
);

const getCompanyMine = (state: ApplicationState) => state.company.companyMine;

const getNavigationItems = (state: ApplicationState) => state.company.selectedCompany.navigation;
const getNavigationItemStatus = (state: ApplicationState, label: string) =>
  state.company.companyMine.navigation.find((item) => item.label === label);

const getCompanyMaxRevisionStage = (state: ApplicationState) => state.company.companyMine.numOfRevStages;
const getAutosaveEnabled = (state: ApplicationState) => state.company.autosaveConfig.autosaveEnabled;
const getRedlineActive = (state: ApplicationState) => state.company.redlineConfig.redlineActive;

const getNavigationTitle = (state: ApplicationState, category: string) => {
  const mainMenuItems = filter(state.company.companyMine.navigation, { type: 'CATEGORY' });
  const subMenuItems = chain(state.company.companyMine.navigation)
    .filter({ type: 'MENU' })
    .map('menu')
    .flatten()
    .value();
  const menuItem = find([...mainMenuItems, ...subMenuItems], { type: 'CATEGORY', category });
  return menuItem?.label;
};

const getNavigationDetails = (state: ApplicationState, category: string) => {
  const mainMenuItems = filter(state.company.companyMine.navigation, { type: 'CATEGORY' });
  const subMenuItems = chain(state.company.companyMine.navigation)
    .filter({ type: 'MENU' })
    .map('menu')
    .flatten()
    .value();
  const menuItem = find([...mainMenuItems, ...subMenuItems], { type: 'CATEGORY', category });
  return { label: menuItem?.label, icon: menuItem?.icon, path: menuItem?.path, type: menuItem?.type };
};

const getActiveLocations = (state: ApplicationState) =>
  chain(state.company.companyMine.locations)
    .filter({ active: true });

const getActiveEmployees = (state: ApplicationState) =>
  chain(state.company.companyMine.employees)
    .filter({ active: true })
    .map((employee) => pick(employee, ['id', 'user.name', 'user.email']))
    .value()
    .sort(sortStringsByKey('user.name'));

const getAllEmployees = (state: ApplicationState) => state.company.companyMine.employees;

const getAllLocations = (state: ApplicationState) => state.company.companyMine.locations;

const getGeneralSettings = (state: ApplicationState) => omit(
  state.company.generalSettings,
  ['id', 'companyId', 'createdAt', 'updatedAt', 'status', 'message'],
);

const getGeneralSettingsId = (state: ApplicationState) => state.company.generalSettings.id;

const getZebraPrintSettings = (state: ApplicationState) => state.company.generalSettings.zebraPrint;

const isAprovalRequestEditingEnabled = (state: ApplicationState) => state.company.generalSettings.approvalRequestAdmin;

const getGeneralSettingsUpdatedAt = (state: ApplicationState) => state.company.generalSettings.updatedAt;

const getGeneralSettingsAsyncState = (state: ApplicationState) => ({
  status: state.company.generalSettings.status,
  message: state.company.generalSettings.message,
});

const getPremiumFeatureSubscriptions = (state: ApplicationState): PremiumFeatureSubscription[] => state.company.premiumFeatureSubscriptions.list;

const getPremiumFeatureSubscriptionsUpdatedAt = createSelector(
  getPremiumFeatureSubscriptions,
  (subscriptions) => moment.max(subscriptions.map(subscription => moment(subscription.updatedAt))),
);

const getSubscriptionsAsyncState = (state: ApplicationState): AsyncState => ({
  status: state.company.premiumFeatureSubscriptions.status,
  message: state.company.premiumFeatureSubscriptions.message,
});

const hasActivePremiumFeatureSubscription = createSelector(
  getPremiumFeatureSubscriptions,
  (state: ApplicationState, subscriptionType: PremiumFeature) => subscriptionType,
  (subscriptions, subscriptionType) => subscriptions.some(
    subscription => subscription.premiumFeature === subscriptionType && subscription.isEnabled,
  ),
);

export default {
  getWhiteLabeling,
  isSlackIntegrationEnabled,
  slackTeamId,
  getSelectedCompany,
  companyIdSelector,
  companyNameSelector,
  getCompanyMine,
  isHelloSignIntegrationEnabled,
  getNavigationItems,
  getNavigationItemStatus,
  getCompanyMaxRevisionStage,
  getAllWhiteLabeling,
  getAutosaveEnabled,
  getRedlineActive,
  getNavigationTitle,
  getNavigationDetails,
  getActiveEmployees,
  getAllEmployees,
  getActiveLocations,
  getAllLocations,
  getGeneralSettings,
  getGeneralSettingsId,
  getGeneralSettingsAsyncState,
  getGeneralSettingsUpdatedAt,
  getPremiumFeatureSubscriptionsUpdatedAt,
  getZebraPrintSettings,
  isAprovalRequestEditingEnabled,
  getPremiumFeatureSubscriptions,
  getSubscriptionsAsyncState,
  hasActivePremiumFeatureSubscription,
};
