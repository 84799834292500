export enum PreviewControlButtons {
  ApproveAndReleaseButton = 'approveAndReleaseButton',
  SubmitApprovalButton ='submitApprovalButton',
  NewCloneEB='NewCloneEB',
  NewClonePO='NewClonePO',
  GeneratePDF = 'generatePDF',
  ExportCsvButton='exportCsvButton',
  RedlineButton='redlineButton',
  CancelButton='cancelButton',
  UpgradeContainerButton='upgradeContainerButton',
  InspectionControl='inspectionControl',
  ChangeOwner='changeOwner',
  VoidButton='voidButton',
  ExportButton='exportButton',
  CloneButton='cloneButton',
  UpdateButton='updateButton',
  NewcloneButton='newcloneButton',
  OutputButton='outputButton',
  RequestAccess='requestAccess',
  WithdrawSubmission='withdrawSubmission',
  Pdf='pdf',
  ExportToEmail='exportToEmail',
  EditPartButton='restrictedEditPart',
  CancelEditPartButton='cancelEditPart',
  PrintLabelButton='printLabelButton',
  WithdrawButton='withdrawButton',
}
