import { LinearProgress, makeStyles } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { translate } from '../../../../../../../../common/intl';
import {
  FBAttachment,
  FBFilePresenterDownloadType,
  FBFilePresenterState,
  FBFilePreview,
} from '../../../../../../../../ui/form.builder';
import { withOCRFilePresenter } from './wrap';

export interface OCRFilePresenterProps {
  loadFIle?: () => void
  handleDownload?: (type: FBFilePresenterDownloadType) => void
  handleRemove?: () => void
  setFile?: Dispatch<SetStateAction<FBAttachment>>
  filePresenterState?: FBFilePresenterState
  loading?: boolean
  file: FBAttachment
  isSupportedFileType?: boolean
}

const useStyle = makeStyles((theme) => ({
  progress: {
    marginTop: theme.spacing(0.5),
  },
}));

const OCRFilePresenter: React.FC<OCRFilePresenterProps> = ({
  file,
  loading,
  isSupportedFileType,
}) => {
  const classes = useStyle();

  if (loading) {
    return <LinearProgress className={classes.progress} />;
  }

  if (!isSupportedFileType) {
    return <span>{translate('ocr.file.preview.not.support.message')}</span>;
  }

  return <FBFilePreview file={file} />;
};

export default withOCRFilePresenter(OCRFilePresenter);
