import { Chip } from '@material-ui/core';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import useStyles from '../../../../../src/ui/components/common/kendo.column.templates/Status.styles';
import { statusClassMapping } from '../../../components/common/kendo/helpers';
import Tooltip from '../../../components/common/kendo/Tooltip';
import { Mode, MODE_FIELD } from '../../../components/KendoDataGrid/constants';
import { getStatusLabel } from '../../../dashboard.new/line.items/common/DocIcon';
import { EMPTY_VALUE_PLACEHOLDER } from '../constants';
import { CustomTemplateProps, EditableProductORService } from '../types';

export const StatusCell: React.FC<CustomTemplateProps> = ({
  dataItem,
  field,
}) => {
  const classes = useStyles();
  let defaultValue = dataItem[field];
  const formik = useFormikContext<EditableProductORService>();

  if ([Mode.add, Mode.edit].includes(dataItem[MODE_FIELD])) {
    defaultValue = formik?.values.status || dataItem[field];
  }

  const displayStatus = getStatusLabel('', defaultValue, '');

  if (defaultValue) {
    return (
      <Tooltip
        data={displayStatus}
        childrenElement={
          <Chip
            size="small"
            className={cx(
              classes.chip,
              classes[statusClassMapping[displayStatus]],
            )}
            label={displayStatus}
          />
        }
      />
    );
  }

  return <>{EMPTY_VALUE_PLACEHOLDER}</>;
};
