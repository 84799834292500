import { MODE_FIELD } from '../../components/KendoDataGrid/constants';

export const EMPTY_VALUE_PLACEHOLDER = '-';
export const FIELDS_TO_OMIT = [MODE_FIELD];
export const ATTACHMENT_FIELDS_TO_OMIT = [
  'company',
  'importMetadataJson',
  'owner',
  'cleanCopyStatus',
  'companyId',
  'createdAt',
  'updatedAt',
  'billIds',
];
