import { get, isEmpty, isUndefined, omit, set } from 'lodash';
import { useObserver } from 'mobx-react';
import React from 'react';
import { v4 as generateUuid } from 'uuid';
import { FB, FBApprovalMatrixFieldData, FBApprovalMatrixFieldModes, FBApprovalMatrixItem } from '..';
import { SM } from '../../../App';
import { DocumentRevisionStatus } from '../../../state/ducks/documentRevisions/types';
import { DEFAULT_CATEGORY_LABEL } from './constants';
import { FBApprovalMatrixProps } from './types';

export const withFBApprovalMatrix = <T extends FBApprovalMatrixProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    handleSave,
    disabled,
    name = '',
    mode = FBApprovalMatrixFieldModes.NONE,
    isInputOwner,
    data,
    withoutStatus,
    size,
    ...props
  }: T) => {
    const { approvalMatrixFieldState, workspaceState } = FB.useStores();
    const { _tabsState } = SM.useStores();

    isInputOwner = workspaceState?.getIsInputOwner(name);
    disabled = !isInputOwner || disabled
      || (workspaceState && !['design', 'form'].includes(workspaceState.mode));

    handleSave = (data: FBApprovalMatrixItem[]) => {
      const approvalMatrix = data.map(({ id, docType, ...restItem }) => {
        if (docType.updatedCategory !== DEFAULT_CATEGORY_LABEL) {
          set(docType, 'category', docType.updatedCategory);
        }
        return ({
          id: id ?? generateUuid(),
          docType: {
            ...omit(docType, 'updatedCategory'),
            acronym: docType.acronym.toUpperCase(),
          },
          ...restItem,
        });
      });

      if (isEmpty(approvalMatrix)) { return; }

      approvalMatrixFieldState?.setMode(FBApprovalMatrixFieldModes.NONE);
      approvalMatrixFieldState?.setApprovalMatrix(approvalMatrix);
    };

    const { document, formInput } = workspaceState ?? {};
    const { status } = document ?? {};

    useObserver(() => {
      const approvalRoles = approvalMatrixFieldState?.getCurrentData('roles')
        ?? approvalMatrixFieldState?.extractData(formInput as FBApprovalMatrixFieldData, 'roles')
        ?? [];

      const approvalMatrix = approvalMatrixFieldState?.getCurrentData('matrix')
        ?? approvalMatrixFieldState?.extractData(formInput as FBApprovalMatrixFieldData, 'matrix')
        ?? [];

      // Clear statuses for deprecated documents and items without active status.
      withoutStatus = status === DocumentRevisionStatus.Deprecated
        || approvalMatrix.some(item => isUndefined(get(item, 'docType.active', undefined)));

      const matrix = (approvalMatrix ?? []).map(item => {
        if (withoutStatus) {
          set(item, 'docType.active', undefined);
        }
        item.docType.updatedCategory = isEmpty(item.docType.category) ? DEFAULT_CATEGORY_LABEL : item.docType.category;
        return item;
      });

      data = {
        ...approvalMatrixFieldState?.data as FBApprovalMatrixFieldData,
        approvalMatrix: matrix,
        approvalRoles,
      };
    });

    size = useObserver(() => _tabsState?.contentSize);

    return Component({
      ...(props as T),
      handleSave,
      disabled,
      name,
      mode,
      data,
      withoutStatus,
      size,
    });
  };

  return (props: T) => Comp(props);
};
